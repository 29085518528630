import React from "react";
import "./App.scss";
import {
  RouterProvider,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import Home from "./pages/Home";
import Contacto from "./pages/Contact";
import Dashboard from "./pages/Dashboard";
import NotFound from "./pages/NotFound";
import Technology from "./pages/Technology";
/* import Documents from "./pages/resources/Documents";
import Videos from "./pages/resources/Videos"; */
import Product from "./pages/Product";
import AboutUs from "./pages/AboutUs";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Aquaculture from "./pages/appplications/Aquaculture";
import Agriculture from "./pages/appplications/Agriculture";
import CoolingTowers from "./pages/appplications/CoolingTowers";
import OilAndGas from "./pages/appplications/OilAndGas";
import IceProduction from "./pages/appplications/IceProduction";
import WaterTreatment from "./pages/appplications/WaterTreatment";
import ThermoelectricPlants from "./pages/appplications/ThermoelectricPlants";
import AdvancedMolecularDisinfection from "./pages/appplications/AdvancedMolecularDisinfection";
import FoodPreservation from "./pages/appplications/FoodPreservation";
import Livestock from "./pages/appplications/Livestock";
import ZebraMusselAndQuagga from "./pages/appplications/ZebraMusselAndQuagga";
import PoultryAndEggWashing from "./pages/appplications/PoultryAndEggWashing";


function App() {
  let PATH = process.env.REACT_APP_ROOT_PATH;

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Home />}>
        <Route index element={<Dashboard />} />
        <Route path="contact" element={<Contacto />} />
        <Route path="technology" element={<Technology />} />
        <Route path="aboutus" element={<AboutUs />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="product" element={<Product />} />
{/*     <Route path="resources/documents" element={<Documents />} />
        <Route path="resources/videos" element={<Videos />} /> */}
        <Route path="applications/aquaculture" element={<Aquaculture />} />
        <Route path="applications/agriculture" element={<Agriculture />} />
        <Route path="applications/cooling-towers" element={<CoolingTowers />} />
        <Route path="applications/oil-and-gas" element={<OilAndGas />} />
        <Route path="applications/ice-production" element={<IceProduction />} />
        <Route path="applications/thermoelectric-plants" element={<ThermoelectricPlants />} />
        <Route path="applications/water-treatment" element={<WaterTreatment />} />
        <Route path="applications/advanced-molecular-disinfection" element={<AdvancedMolecularDisinfection />} />
        <Route path="applications/food-preservation" element={<FoodPreservation />} />
        <Route path="applications/livestock" element={<Livestock />} />
        <Route path="applications/zebra-mussel-and-quagga" element={<ZebraMusselAndQuagga />} />
        <Route path="applications/poultry-and-egg-washing" element={<PoultryAndEggWashing />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    ),
    { basename: PATH }
  );

  return (
      <RouterProvider router={router} />
  );

}

export default App;
