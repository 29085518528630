import TitleContainer from "../components/TitleContainer";
import { FormattedMessage, useIntl } from "react-intl";
import SectionContainer from "../components/SectionContainer";
import TitleText from "../components/TitleText";
import ResponsiveImage from "../components/ResponsiveImage";
import image1 from "../assets/images/product_image1.png";
import image2 from "../assets/images/product_image2.png";
import image3 from "../assets/images/product_image3.png";
import image4 from "../assets/images/product_image4.png";

const Product = () => {
  const intl = useIntl();

  return (
    <div>
      <TitleContainer>
        <FormattedMessage id="product.title" />
      </TitleContainer>
      <SectionContainer
        id="text1"
        sectionStart={
          <TitleText
            title={
              <h2 className="p-2 p-lg-3 bg-dark text-center">
                <FormattedMessage id="product.text1.title" />
              </h2>
            }
            description={<ResponsiveImage src={image1} />}
          />
        }
      ></SectionContainer>
      <SectionContainer
        id="text2"
        sectionStart={
          <TitleText
            title={
              <h2 className="p-2 p-lg-3 bg-dark text-center">
                <FormattedMessage id="product.text2.title" />
              </h2>
            }
            description={<ResponsiveImage src={image2} />}
          />
        }
      ></SectionContainer>
      <SectionContainer
        id="text3"
        sectionStart={
          <TitleText
            title={
              <h2 className="p-2 p-lg-3 bg-dark text-center">
                <FormattedMessage id="product.text3.title" />
              </h2>
            }
            description={<ResponsiveImage src={image3} />}
          />
        }
      ></SectionContainer>
      <SectionContainer
        id="text4"
        sectionStart={
          <TitleText
            title={
              <h2 className="p-2 p-lg-3 bg-dark text-center">
                <FormattedMessage id="product.text4.title" />
              </h2>
            }
            description={<ResponsiveImage src={image3} />}
          />
        }
      ></SectionContainer>
      <SectionContainer
        id="text5"
        header={intl.formatMessage({
          id: "product.text5.header",
        })}
        headerClassNames="p-2 p-lg-3 bg-dark"
        sectionStart={
          <TitleText
            title={
              <h4 className="text-center">
                <FormattedMessage id="product.text5.title.part1" />
              </h4>
            }
            description={<ResponsiveImage src={image4} className="w-50" />}
          />
        }
        sectionEnd={
          <TitleText
            title={
              <h4 className="text-center">
                <FormattedMessage id="product.text5.title.part2" />
              </h4>
            }
            description={<ResponsiveImage src={image4} className="w-75" />}
          />
        }
      ></SectionContainer>
      <SectionContainer
        id="text6"
        sectionStart={
          <TitleText
            title={
              <p className="text-center">
                <strong>
                  <FormattedMessage id="product.text6.title" />
                </strong>
              </p>
            }
          />
        }
      ></SectionContainer>
    </div>
  );
};

export default Product;
