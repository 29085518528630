import { useIntl, FormattedMessage } from "react-intl";
import image1 from "../../assets/images/zebra_mussel_and_quagga_image1.png";
import TitleContainer from "../../components/TitleContainer";
import SectionContainer from "../../components/SectionContainer";
import ApplicationBenefits from "../../components/ApplicationBenefits";
import useIntlMessages from "../../hooks/useIntlMessages";
import SectionWrapper from "../../components/SectionWrapper";
import VimeoFrame from "../../components/VimeoFrame";
import { Container } from "react-bootstrap";
import TitleText from "../../components/TitleText";
import ResponsiveImage from "../../components/ResponsiveImage";

const ZebraMusselAndQuagga = () => {
  const intl = useIntl();
  const benefitsList = useIntlMessages(
    "applications.zebraMusselAndQuagga.benefitsList"
  );

  return (
    <div>
      <TitleContainer>
        <FormattedMessage id="components.header.zebraMusselAndQuagga" />
      </TitleContainer>
      <SectionWrapper id="videoIntro">
        <Container>
          <VimeoFrame
            src={intl.formatMessage({
              id: "applications.zebraMusselAndQuagga.videoIntroUrl",
            })}
            title={intl.formatMessage({
              id: "components.header.zebraMusselAndQuagga",
            })}
          ></VimeoFrame>
        </Container>
      </SectionWrapper>
      <SectionContainer
        id="benefits"
        classNames="bg-dark"
        sectionStart={
          <TitleText
            header={intl.formatMessage({
              id: "applications.benefits.title",
            })}
            title={<ApplicationBenefits benefitsList={benefitsList} />}
          />
        }
      ></SectionContainer>
      <SectionContainer
        id="text1"
        sectionStart={
          <TitleText
            title={intl.formatMessage({
              id: "applications.zebraMusselAndQuagga.text1.title",
            })}
          />
        }
        sectionEnd={<ResponsiveImage src={image1} />}
      ></SectionContainer>
    </div>
  );
};

export default ZebraMusselAndQuagga;
