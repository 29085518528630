import { Container } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import TitleContainer from "../components/TitleContainer";


const PrivacyPolicy = () => {
 
  return (
    <div>
      <TitleContainer>
        <FormattedMessage id="privacyPolicy.title" />
      </TitleContainer>
      <Container>
        <p className="text-section-container"><FormattedMessage id="privacyPolicy.content" /></p>
      </Container>
    </div>
  );
};

export default PrivacyPolicy;